import {
  Button,
  CalloutCard, Card, Heading, TextContainer
} from "@shopify/polaris";
import { APP_CONFIG } from "base/consts/app";
import type { RootState } from "base/redux/reducer";
// import { ActivityList } from "business/dashboard/activityList";
import { GetStarted } from "business/dashboard/GetStarted";
// import { ReferralChart } from "business/dashboard/referralChart";
// import { RevenueChart } from "business/dashboard/revenueChart";
// import { ReviewCard } from "business/dashboard/ReviewCard";
import { SupportCard } from "business/dashboard/SupportCard";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import type { FC } from "react";
import { useSelector } from "react-redux";

const Dashboard: FC = () => {
  const { t: translate } = useTranslation("dashboard");

  const { isGetStarted } = useSelector((state: RootState) => state.dashboard);

  return (
    <>
      <Head>
        <title>
          {translate("dashboard")} | {APP_CONFIG.APP_NAME}
        </title>
      </Head>
      <div className="dashboard-page">
        <div className="dashboard-content">
          {!isGetStarted && <GetStarted />}
          {/* <ResourceCard /> */}
          <Card sectioned>
            <div className="card-center">
              <div>
                <img
                  alt=""
                  src="https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/announcements/returns-simple-df754e9a2e1ab106c76040ccf9822b7a3289c949ad80f06fb5f15a123fb97ec3.svg"
                  className="card-illustrator"
                />
              </div>
              <TextContainer spacing="tight">
                <Heading>
                  Turn first customers into loyal customers
                </Heading>
                <p>
                  Increase repeat sales and increase customer lifetime value by creating a points program, a referral program.
                </p>
              </TextContainer>
              <div className="card-footer">
                <Button external url="https://apps.shopify.com/hoora-loy">Get started for free</Button>
              </div>
            </div>
          </Card>
          <SupportCard />
          <CalloutCard
            title="Customize the style of your widget"
            illustration="https://loy-static.hoora.io/images/illustrators/paint.svg"
            primaryAction={{
              content: "Customize widget",
              url: "/branding/widget",
            }}
          >
            <p>
              Upload your store logo, customize primary colors, fonts and more.
            </p>
          </CalloutCard>
          {/* <ReviewCard /> */}
        </div>
        {/* <div className="dashboard-sidebar">
          <div className="dashboard-sidebar-wrapper">
            {/* <div className="dashboard-sidebar-card">
              <RevenueChart />
            </div>
            <div className="dashboard-sidebar-card">
              <ReferralChart />
            </div>
            <div className="dashboard-sidebar-card">
              <ActivityList />
            </div>
          </div>
        </div> */}
      </div>
      <style jsx global>
        {`
        .card-center {
          align-items: center;
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: auto;
          max-width: 33.75rem;
        }
        .card-illustrator {
          margin: 0.25rem auto 1rem;
          max-height: 7.8125rem;
          max-width: 18.75rem;
        }
        .card-footer {
          margin-top: var(--p-space-5);
        }
        #AppFrameMain {
          padding-bottom: 0;
        }
      `}
      </style>
      <style jsx>{`
        @media screen and (max-width: 1368px) {
          .dashboard-page {
            flex-direction: column;
            --sidebar-width: 100% !important;
            --content-width: 100% !important;
            padding: 0 var(--p-space-6);
            padding-bottom: var(--p-space-6);
          }
          .dashboard-sidebar {
            border-radius: var(--p-border-radius-2);
          }
        }
        .dashboard-sidebar-card {
          border-bottom: 1px solid #dfe3e8;
          padding: 1rem;
        }
        .dashboard-page {
          display: flex;
          --sidebar-width: 380px;
          --content-width: 45.25rem;
        }
        .dashboard-content {
          max-width: var(--content-width);
          margin: 20px auto;
          width: 100%;
        }
        .dashboard-sidebar {
          width: var(--sidebar-width);
          box-shadow: 0 0 0 1px #3f3f440d, 0 1px 3px 0 #3f3f4426;
          background-color: #fff;
        }
      `}</style>
    </>
  );
};

export default Dashboard;
