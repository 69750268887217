import { Badge } from '@shopify/polaris';
import { Status } from '@shopify/polaris/build/ts/latest/src/components/Badge';
import React, { FC } from 'react';

interface IBadgeProps {
  value: string | undefined;
}

export const CustomBadge: FC<IBadgeProps> = ({ value }) => {
  const SUCCESS_VALUE = ['success', 'completed', 'activated', 'approved'];
  const ERROR_VALUE = ['error', 'fail'];
  const WARNING_VALUE = ['warning', 'deactivated', 'spam'];
  const INFO_VALUE = ['running'];

  const convertValueToBadge = (value: string): Status | undefined => {
    if (SUCCESS_VALUE.includes(value.toLowerCase())) {
      return 'success';
    }
    if (ERROR_VALUE.includes(value.toLowerCase())) {
      return 'warning';
    }
    if (WARNING_VALUE.includes(value.toLowerCase())) {
      return 'critical';
    }
    if (INFO_VALUE.includes(value.toLowerCase())) {
      return 'info';
    }

    return undefined;
  };

  return <Badge status={convertValueToBadge(value || '')}>{value}</Badge>;
};
