import type { IconSource } from '@shopify/polaris';
import {
  Button,
  Card,
  Heading,
  Icon,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import {
  AppsMinor,
  ChevronDownMinor,
  ChevronRightMinor,
  OnlineStoreMinor,
  TemplateMinor,
} from '@shopify/polaris-icons';
import { CustomBadge } from 'base/components/badge';
import { ConfirmModal } from 'base/components/modals/confirmModal';
import { useUserContext } from 'base/context/user';
import { useModal } from 'base/hooks/useModal';
import type { RootState } from 'base/redux/reducer';
import classnames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import type { FC, PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startDismissingGetStarted } from '../slice';

export interface IGetStartedItemProps {
  icon: IconSource;
  title: string;
  open: boolean;
  completed?: boolean;
  onToggle: () => void;
}

export const GetStartedItem: FC<PropsWithChildren<IGetStartedItemProps>> = ({
  icon,
  title,
  children,
  open,
  onToggle,
  completed = false,
}) => {
  const { t: translate } = useTranslation('dashboard');
  return (
    <>
      <div
        className={classnames(
          'Polaris-Card__Section',
          completed && 'Polaris-Card__Section--completed'
        )}
      >
        <div className="get-started-item-heading" onClick={onToggle}>
          <Stack alignment="center">
            <Stack.Item>
              <Icon source={icon} />
            </Stack.Item>
            <Stack.Item fill>
              <TextStyle variation="strong">{title}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Stack alignment="center">
                {completed && <CustomBadge value={translate('completed')} />}
                <Button
                  plain
                  icon={
                    (open ? ChevronDownMinor : ChevronRightMinor) as IconSource
                  }
                />
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
        {open && (
          <div className="get-started-item-content">
            <div className="get-started-item-card">{children}</div>
          </div>
        )}
      </div>
      <style jsx>{`
        .Polaris-Card__Section {
          padding: 0;
        }
        .Polaris-Card__Section--completed {
          background-color: var(--p-surface-success-subdued);
        }
        .get-started-item-heading {
          padding: var(--p-space-5);
          cursor: pointer;
          transition: background-color var(--p-linear) var(--p-duration-400);
        }
        .get-started-item-heading:hover {
          background-color: var(--p-background-hovered);
        }
        .Polaris-Card__Section--completed .get-started-item-heading:hover {
          background-color: var(--p-surface-success-subdued-pressed);
        }
        .get-started-item-content {
          border-top: var(--p-border-divider);
          padding: var(--p-space-5);
        }
        .get-started-item-card {
        }
      `}</style>
    </>
  );
};

export const GetStarted: FC = () => {
  const { t: translate } = useTranslation('dashboard');

  const [isDismissModal, onCloseDismissModal, onOpenDismissModal] = useModal();

  const { shopifyShop } = useUserContext();

  const dispatch = useDispatch();
  const { isDismissingGetStarted } = useSelector(
    (state: RootState) => state.dashboard
  );

  const [step, setStep] = useState('step1');

  const onToggle = useCallback(
    (s: string) => {
      setStep(s === step ? '' : s);
    },
    [step]
  );

  const onDismissGetStarted = useCallback(() => {
    dispatch(startDismissingGetStarted());
    onCloseDismissModal();
  }, []);

  return (
    <>
      <Card
        title={translate('getStarted')}
        // actions={[
        //   {
        //     content: translate('dismiss'),
        //     onAction: onOpenDismissModal,
        //   },
        // ]}
      >
        <Card.Section>
          <p>{translate('getStartedDescription')}</p>
        </Card.Section>
        <GetStartedItem
          open={step === 'step1'}
          onToggle={() => onToggle('step1')}
          icon={AppsMinor as IconSource}
          title={translate('getStartedStep1')}
        >
          <TextContainer>
            <Heading>{translate('getStartedStep1Heading')}</Heading>
            <p>{translate('getStartedStep1Description')}</p>
          </TextContainer>
        </GetStartedItem>
        <GetStartedItem
          open={step === 'step2'}
          onToggle={() => onToggle('step2')}
          icon={OnlineStoreMinor as IconSource}
          title={translate('getStartedStep2')}
        >
          <TextContainer>
            <Heading>{translate('getStartedStep2Heading')}</Heading>
            <p>{translate('getStartedStep2Description')}</p>
            <Button plain url="/branding/widget">
              {translate('customizeWidgetButton')}
            </Button>
          </TextContainer>
        </GetStartedItem>
        <GetStartedItem
          open={step === 'step3'}
          onToggle={() => onToggle('step3')}
          icon={TemplateMinor as IconSource}
          title={translate('getStartedStep3')}
        >
          <TextContainer>
            <Heading>{translate('getStartedStep3Heading')}</Heading>
            <p>{translate('getStartedStep3Description')}</p>
            <Button plain url="/reviews">
              {translate('manageReviewsButton')}
            </Button>
          </TextContainer>
        </GetStartedItem>
      </Card>
      <ConfirmModal
        open={isDismissModal}
        onClose={onCloseDismissModal}
        message={translate('confirmCloseGetStartedMessage')}
        title={translate('confirmCloseGetStartedTitle')}
        onAction={onDismissGetStarted}
        loading={isDismissingGetStarted}
        confirmText={translate('confirmCloseGetStartedConfirmText')}
      />
    </>
  );
};
