import { Card, Icon, Stack, TextStyle } from "@shopify/polaris";
import type { IconSource } from "@shopify/polaris";

import useTranslation from "next-translate/useTranslation";
import type { FC } from "react";
import { ConversationMinor, QuestionMarkMinor, ConnectMinor, PlayMinor } from "@shopify/polaris-icons";
import { APP_CONFIG } from "base/consts/app";

export interface ISupportCardItemProps {
  icon: IconSource;
  name: string;
  description: string;
  url?: string;
  onClick?: () => void;
}

const SupportCardItem: FC<ISupportCardItemProps> = ({
  icon,
  name,
  description,
  onClick,
  url
}) => (
  <>
    <div className="support-grid-item" onClick={onClick}>
      {url && <a rel="noreferrer" target="_blank" href={url} className="support-grid-link" />}
      <Stack vertical spacing="tight">
        <Stack alignment="center">
          <Icon source={icon} />
          <TextStyle variation="strong">{name}</TextStyle>
        </Stack>
        <p>
          <TextStyle variation="subdued">{description}</TextStyle>
        </p>
      </Stack>
    </div>
    <style jsx>{`
      .support-grid-link {
        position: absolute;
        inset: 0;
      }
      .support-grid-item {
        position: relative;
        cursor: pointer;
        padding: var(--p-space-4);
        transition: background-color var(--p-duration-400) var(--p-linear);
        border-radius: var(--p-border-radius-1);
      }
      .support-grid-item:hover {
        background-color: var(--p-background-hovered);
      }
    `}</style>
  </>
);

export const SupportCard: FC = () => {
  const { t: translate } = useTranslation("dashboard");

  return (
    <>
      <Card title={translate("support")}>
        <Card.Section>
          <p>{translate("supportDescription")}</p>
        </Card.Section>
        <Card.Section>
          <div className="support-grid">
            <SupportCardItem
              icon={ConversationMinor}
              name={translate("startLiveChat")}
              description={translate("startLiveChatDescription")}
              onClick={() => {}}
            />
            <SupportCardItem
              icon={QuestionMarkMinor}
              name={translate("openHelpCenter")}
              description={translate("openHelpCenterDescription")}
              url={APP_CONFIG.HELP_CENTER_URL}
            />
            <SupportCardItem
              icon={ConnectMinor}
              name={translate("joinInFacebookGroup")}
              description={translate("joinInFacebookGroupDescription")}
              url={APP_CONFIG.FACEBOOK_GROUP_URL}
            />
            <SupportCardItem
              icon={PlayMinor}
              name={translate("viewYoutubeChannel")}
              description={translate("viewYoutubeChannelDescription")}
              url={APP_CONFIG.YOUTUBE_URL}
            />
          </div>
        </Card.Section>
      </Card>
      <style jsx>{`
        .support-grid {
          display: grid;
          grid-template-columns: auto auto;
          gap: var(--p-space-4);
        }
      `}</style>
    </>
  );
};
